<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-account</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Team ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="appbar__btn mr-8"
        height="40px"
        right
        :to="{ name: 'teams.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Team Member
      </v-btn>

      <v-menu offset-y>
        <!--        <template v-slot:activator="{ on, attrs }">-->
        <!--          <v-btn-->
        <!--            color="primary"-->
        <!--            class="appbar__btn mr-8"-->
        <!--            height="40px"-->
        <!--            dark-->
        <!--            v-bind="attrs"-->
        <!--            v-on="on"-->
        <!--          >-->
        <!--            Bulk Actions-->
        <!--          </v-btn>-->
        <!--        </template>-->
        <v-list>
          <v-list-item v-for="(item, index) in actions" :key="index">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip>
        <template v-slot:activator="{ on }">
          <v-btn
            color="#383d3f"
            class="mr-1"
            icon
            right
            @click="exportList"
            v-on="on"
            :disabled="loading"
          >
            <v-icon>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div
      class="mx-4"
      id="team-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            :disabled="defaultStatus !== 'active' && loading"
            @change="
              defaultStatus = 'active'
              filterList()
            "
          >
            Active
          </v-tab>
          <v-tab
            :disabled="defaultStatus !== 'blocked' && loading"
            @change="
              defaultStatus = 'blocked'
              filterList()
            "
          >
            Deactivated
          </v-tab>
          <v-tab
            :disabled="defaultStatus !== 'deleted' && loading"
            @change="
              defaultStatus = 'deleted'
              filterList()
            "
          >
            Deleted
          </v-tab>
          <v-tab
            :disabled="defaultStatus !== 'all' && loading"
            @change="
              defaultStatus = 'all'
              filterList()
            "
          >
            All
          </v-tab>
        </v-tabs>

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, email"
          @input="search"
        ></v-text-field>

        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :disabled="loading"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterList"
        />

        <v-btn
          depressed
          class="ml-2"
          :disabled="loading"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterList()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :disabled="loading"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterList()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="teams"
        :server-items-length="listMeta.total"
        :options.sync="options"
        :loading="loading"
        :v-model="selected"
        :show-select="false"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :hide-default-footer="loading"
      >
        <template v-slot:item.full_name="{ item }">
          {{ item.full_name }}
        </template>
        <template v-slot:item.property="{ item }">
          {{ item?.property?.name }}
        </template>
        <template v-slot:item.roles="{ item }">
          {{ item?.flatRoleName }}
        </template>
        <template v-slot:item.onboarded_at="{ item }">
          <v-checkbox
            disabled
            v-model="item.isOnboarded"
            readonly
            hide-details
          />
        </template>
        <template v-slot:item.building="{ item }">
          <div v-if="item.roles[0].toString() === ('ADMIN' || 'SUPER_ADMIN')">
            All
          </div>
          <div
            v-for="buildingItem in item.buildings"
            :key="buildingItem.id"
            v-else
          >
            {{ buildingItem.name }}
          </div>
        </template>
        <template v-slot:item.blocked_at="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(teams.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Team list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiFileExcel, mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import Status from '@/components/common/Status'

export default {
  name: 'TeamsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    Status,
  },

  data() {
    return {
      selected: [],
      actions: [{ title: 'Delete' }],
      headers: [
        {
          text: 'Name',
          value: 'full_name',
          width: '27%',
        },
        {
          text: 'Email',
          value: 'email',
          width: '13%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '13%',
        },
        {
          text: 'Role',
          value: 'roles',
          width: '13%',
          align: 'center',
        },
        {
          text: 'Onboarded',
          value: 'onboarded_at',
          width: '9%',
          align: 'center',
        },
        {
          text: 'Status',
          value: 'blocked_at',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
      ],
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultStatus: 'active',
      defaultFilter: 'created_at',
      sortOrder: false,
      filterBy: [
        {
          value: 'full_name',
          label: 'Name',
        },
        {
          value: 'email',
          label: 'Email',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      options: {},
    }
  },

  created() {
    this.clearTeams()
    this.fetch(1)
  },

  computed: {
    ...mapState({
      teams: (state) => state.team.list,
      listMeta: (state) => state.team.listMeta,
    }),

    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Blocked',
          value: 'blocked',
        },
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getTeams: 'team/getTeams',
      exportToExcel: 'team/exportToExcel',
    }),

    ...mapMutations({
      clearTeams: 'team/clearTeamList',
    }),

    filterList() {
      this.clearTeams()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearTeams()
      this.fetch()
    }, 600),

    async fetch(page = 1) {
      if (this.loading) return

      let params = {
        page,
        filter: this.defaultStatus,
        sort: this.getSortParam(),
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getTeams(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async exportList() {
      this.loading = true

      await this.exportToExcel({
        sort: this.getSortParam(),
      })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'teams_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
        })
        .finally(() => {
          this.loading = false
        })
    },

    getSortParam() {
      return this.sortOrder ? `${this.defaultFilter}` : `-${this.defaultFilter}`
    },

    editItem(item) {
      this.$router.push({
        name: 'teams.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 200px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }

    &__status {
      max-width: inherit;
      margin-bottom: 15px;
      margin-left: 0;
    }
  }
}
</style>
