var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Team ("+_vm._s(_vm.totalItems)+")")]),_c('v-spacer'),_c('v-btn',{staticClass:"appbar__btn mr-8",attrs:{"color":"primary","height":"40px","right":"","to":{ name: 'teams.create' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v(" Team Member ")],1),_c('v-menu',{attrs:{"offset-y":""}},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"#383d3f","icon":"","right":"","disabled":_vm.loading},on:{"click":_vm.exportList}},on),[_c('v-icon',[_vm._v("mdi-export-variant")])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mx-4",attrs:{"id":"team-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-tabs',[_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'active' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'active'
            _vm.filterList()}}},[_vm._v(" Active ")]),_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'blocked' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'blocked'
            _vm.filterList()}}},[_vm._v(" Deactivated ")]),_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'deleted' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'deleted'
            _vm.filterList()}}},[_vm._v(" Deleted ")]),_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'all' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'all'
            _vm.filterList()}}},[_vm._v(" All ")])],1),_c('v-text-field',{ref:"searchField",staticClass:"appbar__filter",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search name, email"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-select',{staticClass:"ml-10",staticStyle:{"max-width":"200px"},attrs:{"hide-details":"","dense":"","item-text":"label","item-value":"value","disabled":_vm.loading,"items":_vm.filterBy},on:{"change":_vm.filterList},model:{value:(_vm.defaultFilter),callback:function ($$v) {_vm.defaultFilter=$$v},expression:"defaultFilter"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","disabled":_vm.loading,"color":_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = true
          _vm.filterList()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowUp))])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","disabled":_vm.loading,"color":!_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = false
          _vm.filterList()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowDown))])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.teams,"server-items-length":_vm.listMeta.total,"options":_vm.options,"loading":_vm.loading,"v-model":_vm.selected,"show-select":false,"footer-props":{
        'items-per-page-options': [25, 50, 100, -1],
      },"sort-by":_vm.defaultFilter,"sort-desc":_vm.sortOrder,"hide-default-footer":_vm.loading},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch],"click:row":_vm.editItem,"update:sortBy":function($event){_vm.defaultFilter=$event},"update:sort-by":function($event){_vm.defaultFilter=$event},"update:sortDesc":function($event){_vm.sortOrder=$event},"update:sort-desc":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.full_name)+" ")]}},{key:"item.property",fn:function({ item }){return [_vm._v(" "+_vm._s(item?.property?.name)+" ")]}},{key:"item.roles",fn:function({ item }){return [_vm._v(" "+_vm._s(item?.flatRoleName)+" ")]}},{key:"item.onboarded_at",fn:function({ item }){return [_c('v-checkbox',{attrs:{"disabled":"","readonly":"","hide-details":""},model:{value:(item.isOnboarded),callback:function ($$v) {_vm.$set(item, "isOnboarded", $$v)},expression:"item.isOnboarded"}})]}},{key:"item.building",fn:function({ item }){return [(item.roles[0].toString() === ('ADMIN' || 'SUPER_ADMIN'))?_c('div',[_vm._v(" All ")]):_vm._l((item.buildings),function(buildingItem){return _c('div',{key:buildingItem.id},[_vm._v(" "+_vm._s(buildingItem.name)+" ")])})]}},{key:"item.blocked_at",fn:function({ item }){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('Status',{attrs:{"status":item.statusLabel}})],1)]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(item.addedDate)+" ")]}}])}),(!(_vm.teams.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }